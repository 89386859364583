<template>

    <div class="food-tags" :class="{ 'food-tags-flex-column' : food_tag_shown === true}" v-lazy-container="{ selector: 'img' }" @click.stop="food_tag_shown = !food_tag_shown">

        <div v-for="food_tag in foodTags" :key="food_tag" class="food-tag" :class="{ 'food-tag-bottom-margin' : food_tag_shown === true }">
            <img :data-src="require(`../assets/tag_icons/${food_tag}.svg`)" />
            <!-- <transition name="fade"> -->
              <div class="food-tag-name" v-if="food_tag_shown === true">
                {{ getFoodTagName(food_tag)}}
              </div>
            <!-- </transition> -->
        </div>

    </div>



</template>

<script>

export default {
    name: 'FoodTags',
    props: ['food'],
    data () {
      return {
        food_tag_shown: false
      }
    },
    methods: {
      getIcon(food_tag) {
          return 
      },
      getFoodTagName(foodTag) {
        let foodTagName = ""
        if (foodTag === "spicy_level_one") {
          foodTagName = "Spicy"
        } else if (foodTag === "spicy_level_two") {
          foodTagName = "Very spicy"
        } else if (foodTag === "spicy_level_three") {
          foodTagName = "Extremely spicy"
        } else if (foodTag === "sustainable_fishing") {
          foodTagName = "Sustainable fishing"
        } else if (foodTag === "alcohol") {
          foodTagName = "Contains alcohol"
        } else if (foodTag === "dairy_free") {
          foodTagName = "Dairy free"
        } else if (foodTag === "twenty_four_hours") {
          foodTagName = "Available 24 hours"
        } else if (foodTag === "chef_recommended") {
          foodTagName = "Chef recommended"
        } else if (foodTag === "sesame_seeds") {
          foodTagName = "Sesame seeds"
        } else if (foodTag === "alcohol_free") {
          foodTagName = "Alcohol free"
        } else if (foodTag === "gluten_free") {
          foodTagName = "Gluten free"
        } else if (foodTag === "soy_beans") {
          foodTagName = "Soybeans"
        } else {
          foodTagName = foodTag
        }
        return foodTagName
      }
    },
    computed: {
      foodTags() {
        let foodTags = []
        if (this.food.spicy == true) {
          foodTags.push('spicy')
        }
        if (this.food.spicy_level_two == true) {
          foodTags.push('spicy_level_two')
        }
        if (this.food.spicy_level_three == true) {
          foodTags.push('spicy_level_three')
        }
        if (this.food.chef_recommended == true) {
          foodTags.push('chef_recommended')
        }
        if (this.food.pork == true) {
          foodTags.push('pork')
        }
        if (this.food.vegetarian == true) {
          foodTags.push('vegetarian')
        }
        if (this.food.halal == true) {
          foodTags.push('halal')
        }
        if (this.food.dairy == true) {
          foodTags.push('dairy')
        }
        if (this.food.fish == true) {
          foodTags.push('fish')
        }
        if (this.food.alcohol == true) {
          foodTags.push('alcohol')
        }
        if (this.food.alcohol_free == true) {
          foodTags.push('alcohol_free')
        }
        if (this.food.gluten == true) {
          foodTags.push('gluten')
        }
        if (this.food.gluten_free == true) {
          foodTags.push('gluten_free')
        }
        if (this.food.peanuts == true) {
          foodTags.push('peanuts')
        }
        if (this.food.shellfish == true) {
          foodTags.push('shellfish')
        }
        if (this.food.egg == true) {
          foodTags.push('egg')
        }
        if (this.food.twenty_four_hours == true) {
          foodTags.push('twenty_four_hours')
        }
        if (this.food.soy_beans == true) {
          foodTags.push('soy_beans')
        }
        if (this.food.walnuts == true) {
          foodTags.push('walnuts')
        }
        if (this.food.celery == true) {
          foodTags.push('celery')
        }
        if (this.food.mustard == true) {
          foodTags.push('mustard')
        }
        if (this.food.sesame_seeds == true) {
          foodTags.push('sesame_seeds')
        }
        if (this.food.sulfites == true) {
          foodTags.push('sulfites')
        }
        if (this.food.lupin == true) {
          foodTags.push('lupin')
        }
        if (this.food.clams == true) {
          foodTags.push('clams')
        }
        if (this.food.sustainable_fishing == true) {
          foodTags.push('sustainable_fishing')
        }
        if (this.food.dairy_free == true) {
          foodTags.push('dairy_free')
        }
        return foodTags
      }
    }
}

</script>

<style scoped>

.food-tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px 0px 5px 0px;
}

.food-tags-flex-column {
  flex-direction: column !important;
  /* margin-top: 15px; */
}

.food-tag {
    box-sizing: border-box;
    padding: 0px 5px 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.food-tag img {
    width: 15px;
    height: 15px;
}

.food-tag-name {
  font-size: 12.5px;
  font-weight: 600;
  transition: 0.2s;
  margin: 0px 0px 0px 5px;
  text-transform: capitalize;
  animation: fadeIn 0.5s ease-in;
}

.food-tag-bottom-margin {
  margin-bottom: 10px;
}

.food-tag-bottom-margin:last-child {
  margin-bottom: 0px;
}


@keyframes fadeIn {
    0% {opacity: 0;}
    100% { opacity: 1;}
}



</style>