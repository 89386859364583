<template>

    <div class="clone-item-button" @click="cloneItem()">
        <img src="../assets/duplicate.svg" />
        <div class="button-descriptor">Clone item</div>
    </div>


</template>


<script>

export default {
    name: 'CloneItem',
    props: ['newFood', 'menu'],
    data () {
        return {
            error: ''
        }
    },
    created () {
    
    
    },
    methods: {
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        cloneItem() {
            const value = this.newFood
            const self = this;
            var font = ''
            if (this.$store.getters.getSelectedFont != '') {
                font = this.$store.getters.getSelectedFont
            } else {
                font = 'default'
            }
    
            if (!value) {
                return
            }
            this.$http.secured.post(`/app_clone_food/` + this.newFood.id, { food: {
                translation_en: this.newFood.translation_en,
                translation_ru: this.newFood.translation_ru,
                translation_jp: this.newFood.translation_jp,
                translation_sp: this.newFood.translation_sp,
                translation_fr: this.newFood.translation_fr,
                translation_ca: this.newFood.translation_ca,
                translation_kr: this.newFood.translation_kr,
                translation_de: this.newFood.translation_de, 
                translation_zh: this.newFood.translation_zh, 
                translation_nl: this.newFood.translation_nl,
                translation_pt: this.newFood.translation_pt,
                translation_it: this.newFood.translation_it,
                translation_ar: this.newFood.translation_ar,  
                description_en: this.newFood.description_en,
                description_ru: this.newFood.description_ru, 
                description_jp: this.newFood.description_jp,
                description_sp: this.newFood.description_sp,
                description_fr: this.newFood.description_fr,
                description_ca: this.newFood.description_ca, 
                description_kr: this.newFood.description_kr,
                description_de: this.newFood.description_de,  
                description_zh: this.newFood.description_zh,
                description_nl: this.newFood.description_nl,
                description_pt: this.newFood.description_pt,
                description_it: this.newFood.description_it,
                description_ar: this.newFood.description_ar,
                category_header: this.newFood.category_header,
                major_category_header: this.newFood.major_category_header,
                unit: this.newFood.unit,
                price: this.newFood.price,
                second_price_number: this.newFood.second_price_number,
                third_price_number: this.newFood.third_price_number,
                second_unit: this.newFood.second_unit,
                third_unit: this.newFood.third_unit,
                special_tag_for_item: this.newFood.special_tag_for_item,
                orderable: this.orderable,
                spicy: this.newFood.spicy,
                spicy_level_two: this.newFood.spicy_level_two,
                spicy_level_three: this.newFood.spicy_level_three, 
                chef_recommended: this.newFood.chef_recommended, 
                pork: this.newFood.pork, 
                vegetarian: this.newFood.vegetarian,
                halal: this.newFood.halal,
                dairy: this.newFood.dairy,
                fish: this.newFood.fish,
                alcohol: this.newFood.alcohol,
                alcohol_free: this.newFood.alcohol_free,
                gluten: this.newFood.gluten,
                gluten_free: this.newFood.gluten_free,
                peanuts: this.newFood.peanuts,
                shellfish: this.newFood.shellfish,
                egg: this.newFood.egg,
                twenty_four_hours: this.newFood.twenty_four_hours,
                soy_beans: this.newFood.soy_beans,
                walnuts: this.newFood.walnuts,
                celery: this.newFood.celery,
                mustard: this.newFood.mustard,
                sesame_seeds: this.newFood.sesame_seeds,
                sulfites: this.newFood.sulfites,
                lupin: this.newFood.lupin,
                clams: this.newFood.clams,
                menu_id: this.menu.id,
                item_name_font: this.newFood.item_name_font,
                item_price_font: this.newFood.item_price_font,
                item_name_font_colour: this.newFood.item_name_font_colour,
                item_description_font: this.newFood.item_description_font,
                background_colour: this.newFood.background_colour,
                stroke_bottom: this.newFood.stroke_bottom,
                sort: this.newFood.sort,
                item_name_font_size: this.newFood.item_name_font_size,
                container_height: this.newFood.container_height,
                item_type: 'food'                  
                }})
                .then(response => {
                    console.log(response.data)
                    console.log(self)
                    this.$emit('item-cloned-successfully')
                }).catch(error => this.setError(error, 'Cannot create food.')) 

        }
    }

}



</script>


<style scoped>

/* .clone-item-button {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    box-sizing: border-box;
    padding: 5px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    color: white;
    font-weight: 600;
    cursor: pointer;
    z-index: 6000;
    top: -20px;
    left: 90px;
    transition: 0.5s;
}

.clone-item-button:hover > .button-descriptor {
    display: flex;
} */



</style>